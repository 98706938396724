import React, { useEffect } from 'react';
import ReactGA from 'react-ga';
import Page from '../../components/library/Page';
import ConstantValues from '../../constants/ConstantValues';
import { Grid, Typography } from '@mui/material';
import { SEO } from '../../common';
import { formatSEOTitle } from '../../function';
import { useTranslation } from 'react-i18next';

/* @ts-expect-error */
function QuestionAndAnswer(question, answer) {
  return { question, answer };
}

function FAQ() {
  const { t } = useTranslation();
  
  const questionAndAnswers = [
    QuestionAndAnswer(
      t('faq_question_1'),
      t('faq_answer_1'),
    ),
    QuestionAndAnswer(
      t('faq_question_2'),
      t('faq_answer_2'),
      ),
      QuestionAndAnswer(
      t('faq_question_3'),
      t('faq_answer_3'),
    ),
  ];
  useEffect(() => {
    if (document.location.hostname === ConstantValues.SiteHostname) {
      ReactGA.pageview(window.location.pathname);
    }
  }, []);

  const Content = () => {
    return (
      <Grid container spacing={4}>
        {questionAndAnswers.map((item, index) => (
          <React.Fragment key={index}>
            <Grid item xs={12} md={4}>
              <Typography variant="h6">{item.question}</Typography>
            </Grid>
            <Grid item xs={12} md={8}>
              <Typography>{item.answer}</Typography>
            </Grid>
          </React.Fragment>
        ))}
      </Grid>
    );
  };

  return (
    <Page pageTitle={t('text_faq')}>
      {/* @ts-expect-error */}
      <SEO
        title={formatSEOTitle(t('seo_faq_title'))}
        description={t('seo_faq_description')}
      />
      <Content/>
    </Page>
  );
}

export default FAQ;
