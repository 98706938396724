{/* @ts-expect-error */}
async function blobToFile(theBlob, fileName){       
  console.log(theBlob);
  const response = await fetch(theBlob.imageURL, { method: 'GET' });
  console.log(response);
  const blob = await response.blob();
  console.log(blob);
  const file = new File([blob], fileName, { lastModified: new Date().getTime(), type: blob.type });
  console.log(file);
  return file;
}

export default blobToFile;