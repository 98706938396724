const content = {
  content_about_us_developer: 'SignalStickerMaker.com（網頁）, Sigicker（iOS）, SignalSticker Maker（Android）皆由以下開發者開發：',
  content_about_us_ads: '呢個App完全由開發者自資，包括Sever Hosting，network，App Development等成本。廣告係我地嘗試回本嘅唯一途徑。我地已經將廣告帶來嘅影響降到最低了。',
  faq_question_1: '我Upload嘅相會唔會Save落你地嘅Server？',
  faq_answer_1: '唔會！Sticker Pack整好之後，所有相都會Delete走',
  faq_question_2: '我可唔可以修改整好咗嘅Sticker Pack？',
  faq_answer_2: '由於Signal嘅限制，你只可以重新整過成個Sticker Pack。',
  faq_question_3: '可唔可以指定Sticker Pack嘅封面同Sticker嘅Emoji?',
  faq_answer_3: '我地仲整梗！好快有得用！',
};

const text = {
  text_about_us: '關於我們',
  text_developers: '開發者',
  text_advertisement: '廣告',
  text_image_selected: '已選圖片',
  text_maximum: '最多',
  text_supported_format: '支援格式',
  text_field_title: '標題',
  text_field_author: '作者',
  text_make_your_own_sticker: '自己Sticker自己整！',
  text_select_images: '揀相',
  text_create_sticker: '整Sticker',
  text_terms_and_conditions: '條款和細則',
  text_terms_and_conditions_2: '您在此聲明您已閱讀並同意',
  text_optional: '選填',
  text_download_now: '現加就Download啦！',
  text_compability: '兼容性',
  text_compability_android: 'Android 4.4+ 嘅Android手機',
  text_developed_by: '用以下技術整出嚟：',
  text_ssm_on_android: 'SignalStickerMaker可以喺Android用啦！',
  text_android_trade_mark: '"Android", "Android Studio", "Google PlayStore" 及其商標皆為 Google LLC 所有。',
  text_ssm_on_ios: 'SignalStickerMaker可以喺iOS用啦！',
  text_compability_ios_1: '配備 iOS 13+ 嘅 iPhone, iPad, iPod Touch',
  text_compability_ios_2: '配備 macOS 11.0+ 同 Apple M1晶片嘅Mac',
  text_ssm_trade_mark: 'Apple®, Mac®, Mac OS®, iPhone®, iPad®, iPod touch®, App Store®, Swift®, Xcode®，及其商標皆為 Apple Inc.在美國和其他國家的註冊商標。',
  text_faq: '常見問題',
  text_stats: '統計',
  text_total_sticker_packs: '在呢到整過嘅Sticker Pack總數',
  text_terms_privacy_condition: '條款、隱私和細則',
  text_copy_right: '版權所有',
  text_give_app_a_try_question: '試下我地嘅App版本？',
  text_better_experience: '更好嘅體驗',
  text_store_your_sticker: '儲低Sticker',
  text_more_convenient: '更加方便',
  text_no_thanks: '不了！',
  text_creating_sticker: '整緊Sticker',
  text_close: '關閉',
  text_cancel: '取消',
  text_copy_to_clipboard: '複製到剪貼簿',
  text_copied: '已複製',
  text_add_sticker_now: '加Sticker啦',
  text_try_again: '再試一次',
  text_report_to_us: '向我地報告',
  text_reported: '已報告',
  text_image_converting: '轉換緊圖片...',
  text_sticker_created_successfully: '成功整出Sticker！',
  text_service_busy: 'Signal上載服務忙緊，等陣再試。',
  text_upload_failed_try_again: '上載失敗，等陣再試。',
  text_image_uploading: '上載緊圖片...',
  text_step_converting_image: '轉換緊圖片',
  text_step_uploading_image: '上載緊圖片',
  text_step_result: '結果',
};

const language_spec = {
  lang_code: 'hk',
  name: '廣東話',
  flag: '🇭🇰',
};

const menu_items = {
  menu_item_ios: 'iOS',
  menu_item_android: 'Android',
  menu_item_faq: '常見問題',
  menu_item_about_us: '關於我們',
  menu_item_support: '支援',
  menu_item_create_stickers: '創建貼圖',
};

const seo = {
  seo_support_title: '支援',
  seo_support_description: '聯繫我們以獲得支援',
  seo_ios_description: 'SignalStickerMaker.com 嘅 iOS APP 可以喺 App Store下載啦！',
  seo_android_description: 'SignalStickerMaker.com 嘅 Android APP 可以喺 Google Play Store下載啦！',
  seo_about_us_description: 'SignalStickerMaker.com 嘅故事',
  seo_faq_title: '常見問題',
  seo_faq_description: '瀏覽常見問題',
};

const error = {
  error_file_size_title: '圖片超出最大檔案大小',
  error_file_size: '圖片檔案大小超過10 MB。請在上載前進一步降低圖片檔案大小。',
  error_conversion: '圖片轉換失敗。請嘗試使用其他瀏覽器或電腦。'
};

const translation = {
  ...language_spec,
  ...content,
  ...text,
  ...menu_items,
  ...seo,
  ...error,
};

export default translation;
