import _hkTranslation from './hk.json';
// import _enApiTranslation from './zhHK.api.json';
// import _enSeoTranslation from './zhHK.seo.json';

const translation = {
    ..._hkTranslation,
    // ..._hkSeoTranslation,
    // ..._hknApiTranslation
};

export default translation;