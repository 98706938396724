// @ts-nocheck

import { Helmet } from 'react-helmet-async';
import {
  SEO_TITLE,
  SEO_TYPE,
  SEO_DESCRIPTION,
} from '../constants';
export const SEO = ({title, description, type, name}) => {
  title = title || SEO_TITLE;
  description = description || SEO_DESCRIPTION;
  type = type || SEO_TYPE;
  name = name || SEO_TITLE;
  return (
    <Helmet>
      { /* Standard metadata tags */ }
      <title>{title}</title>
      <meta
        name="description"
        content={description}
      />
      <meta
        name="viewport"
        content="width=device-width, initial-scale=1"
      />
      { /* End standard metadata tags */ }
      { /* Facebook tags */ }
      <meta property="og:type" content={type} />
      <meta property="og:title" content={title} />
      <meta property="og:description" content={description} />
      { /* End Facebook tags */ }
      { /* Twitter tags */ }
      <meta name="twitter:creator" content={name} />
      <meta name="twitter:card" content={type} />
      <meta name="twitter:title" content={title} />
      <meta name="twitter:description" content={description} />
      { /* End Twitter tags */ }
      {/* Multi languages tags */}
      <link rel="alternate" hrefLang="x-default" href="https://signalstickermaker.com" />
      <link rel="alternate" hrefLang="en" href="https://signalstickermaker.com/" />
      <link rel="alternate" hrefLang="fr" href="https://signalstickermaker.com/fr" />
      <link rel="alternate" hrefLang="zh-HK" href="https://signalstickermaker.com/hk" />
      <link rel="alternate" hrefLang="zh-TW" href="https://signalstickermaker.com/tw" />
      {/* End Multi languages tags */}
    </Helmet>
  );
};