// @ts-nocheck
import { useState } from 'react';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { useTranslation  } from 'react-i18next';
import translations from '../../../translations';
import { useLocation, useNavigate } from 'react-router-dom';
import Button from '@mui/material/Button';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

function LanguageSelection() {
  const { i18n } = useTranslation();
  console.log(i18n.language);

  const location = useLocation();
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleChange = (language) => {
    const currentPath = location.pathname; // Get the current path
    const pathSegments = currentPath.split('/'); // Split the path into segments
    pathSegments[1] = language; // Update the first value

    const newPath = pathSegments.join('/'); // Join the segments back into a new path
    navigate(newPath); // Navigate to the new path
    handleClose();
  };
  
  const getLanguageDisplay = (languageCode) => {
    let languageSpec = translations[languageCode];
    return `${languageSpec.flag} ${languageSpec.name}`;
  };

  return (
    <>
      <Button
        onClick={handleClick}
        startIcon={<ArrowDropDownIcon />}
        style={{
          maxHeight: '30px',
          minWidth: '30px',
          color: 'black',
        }}
      >
        {getLanguageDisplay(i18n.language)}
      </Button>
      <Menu
        value={i18n.language}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        {
          Object
            .keys(translations)
            .sort()
            .map((language) => {
              console.log(language);
              let languageSpec = translations[language];
              console.log(languageSpec);
              return (
                <MenuItem
                  key={language}
                  value={languageSpec.lang_code}
                  onClick={() => handleChange(languageSpec.lang_code)}
                >
                  {getLanguageDisplay(language)}
                </MenuItem>
              );
            })
        }
      </Menu>
    </>
  );
}

export default LanguageSelection;