import AppBar from '@mui/material/AppBar';
import Grid from '@mui/material/Grid';
import { useTheme } from '@mui/material/styles';
import Toolbar from '@mui/material/Toolbar';
import useMediaQuery from '@mui/material/useMediaQuery';
import React from 'react';
import Logo from '../../components/features/Logo';
import Linker from '../../components/features/Navigation/Linker';

function Header(){
  const theme = useTheme();
  const marginBottom = useMediaQuery(theme.breakpoints.down('sm')) ? 0 : 10;
  const marginLeft = useMediaQuery(theme.breakpoints.down('sm')) ? 0 : 10;
  return(
    <AppBar position='sticky'>
      {/* @ts-expect-error */}
      <Toolbar variant='string' className='bg-brand-dark'>
        <div style={{marginTop: 10, marginLeft: 10, marginBottom: marginBottom, width: '100%'}}>
          <Grid container justifyContent="center">
            {/* @ts-expect-error */}
            <Grid container item xs={12} sm={10} md={8} lg={6} fullWidth style={{display: 'flex', alignItems: 'center' }}>
              <Grid item xs={12} sm={0.5} style={{display: 'flex', justifyContent: 'center'}}>
                <Logo/>
              </Grid>
              <Grid item style={{alignItems: 'baseline', marginLeft: marginLeft}}>
                <Linker/>
              </Grid>
            </Grid>
          </Grid>
        </div>
      </Toolbar>
    </AppBar>
  );
}

export default Header;