import Typography from '@mui/material/Typography';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import React, { useEffect } from 'react';
import ReactGA from 'react-ga';
import Page from '../../components/library/Page';
import Title from '../../components/library/Title';
import ConstantValues from '../../constants/ConstantValues';
import { SEO } from '../../common';
import { formatSEOTitle } from '../../function';
import { useTranslation } from 'react-i18next';

/* @ts-expect-error */
const DeveloperCard = ({ name, email }) => {
  return (
    <Card sx={{ width: 300, mr: 2, mb: 2 }}>
      <CardContent style={{ textAlign: 'center' }}>
        <Typography variant="h5" component="h2">
          {name}
        </Typography>
        <Typography color="textSecondary" gutterBottom>
          {email}
        </Typography>
      </CardContent>
    </Card>
  );
};

function AboutUs(){
  const { t } = useTranslation();

  useEffect(() => {
    if (document.location.hostname === ConstantValues.SiteHostname) { 
      ReactGA.pageview(window.location.pathname);
    }
  },[]);

  const Content = () => {
    return(
      <>
        <Title type="sub" title={t('text_developers')}/>
        <Typography variant="subtitle1" gutterBottom component="div">
          {t('content_about_us_developer')}
        </Typography>
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
          <DeveloperCard name="😎Leo, Yip" email="lyip@lyip.win" />
          <DeveloperCard name="😜Vardy, Ng" email="vardyng1999@gmail.com" />
        </div>
        <Title type="sub" title={t('text_advertisement')}/>
        <Typography variant="subtitle1" gutterBottom component="div">
          {t('content_about_us_ads')}
        </Typography>
      </>
    );
  };
  const aboutUs = t('text_about_us');
  return(
    <Page pageTitle={aboutUs}>
      {/* @ts-expect-error */}
      <SEO
        title={formatSEOTitle(aboutUs)}
        description={t('seo_about_us_description')}
      />
      <Content/>
    </Page>
  );
}


export default AboutUs;
