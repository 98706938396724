// @ts-nocheck
import translations from '../translations';
import {Route, Routes, Navigate, useLocation, useNavigate} from 'react-router-dom';
import { useEffect} from 'react';
import {useTranslation} from 'react-i18next';
import App from '../app';
import languageCodeMapping from '../translations/languageCodeMapping';

function RoutesByLanguage(){
  console.log('RoutesByLanguage');
  // Translation hook
  const { i18n} = useTranslation();
  // React Router location hook
  const loc = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    // Parse language code from the URL path, e.g., test.com/tr/home -> result: tr
    const pathLang = loc.pathname.split(/\/([a-z]{2})(?![^/])/gm)[1];
    console.log(`pathLang: ${pathLang}`);
    
    // Check if the language from the pathname matches any known languages
    const pathLangIndex = Object.keys(translations).findIndex(lang => lang === pathLang);

    // If the language from the pathname is valid and different than the current language, change the language and return early
    console.log(`pathLangIndex: ${pathLangIndex}`);
    if (pathLangIndex !== -1 && i18n.language !== pathLang) {
      i18n.changeLanguage(pathLang);
      return;
    }

    // if path lang is valid, skip checking browser language
    if (pathLangIndex !== -1) return;
    
    // If the language from the pathname is not valid or not present, get the user's preferred language from the browser
    let userLanguage = navigator.language.toLowerCase();
    console.log(navigator.language);
    console.log(`userLanguage: ${userLanguage}`);
    userLanguage = languageCodeMapping[userLanguage];
    console.log(`userLanguage: ${userLanguage}`);
    
    // Check if the user's preferred language is available in translations
    const userLangIndex = Object.keys(translations).findIndex(lang => lang === userLanguage);
    console.log(`userLangIndex: ${userLangIndex}`);
    console.log(`i18n.language: ${i18n.language}`);
    // If the user's preferred language is available and different than the current language, change the language
    if (userLangIndex !== -1 && i18n.language !== userLanguage) {
      console.log(`changeLanguage: ${userLanguage}`);
      i18n.changeLanguage(userLanguage);
      
      // navigate to the user's preferred language
      navigate(userLanguage);
    }
  }, [loc, i18n]);

  function renderRoutes(){
    return Object.keys(translations).map((lang_code, id) => {
      return (
        <>
          {/* Generate url by language. Ex: test.com/en, test.com/en */}
          <Route
            key={id}
            path={`${lang_code}/*`}
            element={<App />}
          />
          {/* Default language */}
          <Route
            key='default'
            path='*'
            element={
              <Navigate 
                to='en'
              />
            }
          />
        </>
      );
    });
  }

  return (
    <Routes>
      {/* If the user visits test.com, redirect them to the appropriate page for their language. For example test.com -> test.com/en */}
      <Route
        index
        element={
          <Navigate 
            to={`${i18n.language}`}
            replace
          />
        }
      />
      {renderRoutes()}
    </Routes>
  );
}

export default RoutesByLanguage;
