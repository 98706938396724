// @ts-nocheck
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import React from 'react';
import { isMobile } from 'react-device-detect';
import ReactGA from 'react-ga';
import Page from '../../components/library/Page';
import MessageDialog from '../../components/MessageDialog';
import ConstantValues from '../../constants/ConstantValues';
import CreateStickerDialog from './CreateStickerDialog';
import MobileDetected from './MobileDetected';
import Statistics from './Statistics';
import PageHeader from './PageHeader';
import { withTranslation  } from 'react-i18next';
import {
  MAXIMUM_IMAGE_NUMBER
} from '../../constants';
import {
  PRIVACY
} from '../../constants/routes';


class CreateSticker extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      stickerTitle: '',
      stickerAuthor: '',
      termsAgreed: false,
      openCreateStickerDialog: false,
      mobileDetected: false,
      selectedImages: [], // array of images selected,
      webpImages: [], // array of images converted
      showImageCountError: false,
      imageErrorMessage: '',
      imageIsProcessing: false,
      debugUseBase64: false,
      showMessageDialog: false,
      messageTitle: '',
      messageContent: '',
      buttonTitle: '',
    };
    this.inputRef = React.createRef();
    
  }

  componentDidMount() {
    console.log(process.env);
    if(isMobile) this.setState({mobileDetected: true});
    this.inputRef.current.addEventListener('change', this.fileSelectorChanged);
    if (document.location.hostname === ConstantValues.SiteHostname) { 
      ReactGA.pageview(window.location.pathname);
    }
  }

  handleAgreeTerms = (event) => {
    this.setState({termsAgreed: event.target.checked});
  };

  handleClose = () => {
    this.setState({openCreateStickerDialog: false});
  };

  handleCloseMobileDetected = () => {
    this.setState({mobileDetected: false});
  };

  handleCloseImageExceededDialog = () => {
    this.setState({showMessageDialog: false});
  };

  startCreateSticker = async () => {
    console.log('startCreateSticker');
    const imageLength = this.state.selectedImages.length;
    console.log(imageLength);
    var useBase64 = true;
    // Check if user has selected a valid number of images
    if(imageLength < 1){
      console.log('no image selected');
      this.setState({
        showImageCountError: true,
        imageErrorMessage: 'Please select image!'
      });
      return;
    } else if (imageLength > MAXIMUM_IMAGE_NUMBER) {
      console.log(`size exceeded ${MAXIMUM_IMAGE_NUMBER}`);
      this.setState({
        showImageCountError: true,
        imageErrorMessage: `Image limit exceeded, please limit your images to ${MAXIMUM_IMAGE_NUMBER} images.`
      });
      return;
    }

    // open dialog
    console.log('open dialog');
    this.setState({openCreateStickerDialog: true, debugUseBase64: useBase64});
    
    if (document.location.hostname === ConstantValues.SiteHostname) { 
      ReactGA.event({
        category: 'Create Sticker',
        action: 'Clicked Create Sticker Button'
      });
    }
  };

  saveSelectedImages = async (event) => {
    console.log('saveSelectedImages');
    const selectedImages = event.target.files;
    console.log(selectedImages);
    // validate image quantity 
    if(selectedImages > MAXIMUM_IMAGE_NUMBER){
      console.log('size exceeded');
      this.setState({showMessageDialog: true, messageTitle: 'Images exceed limit', messageContent: `Maximum ${MAXIMUM_IMAGE_NUMBER} images are allowed, please limit your images.`, buttonTitle: 'OK'});
      document.getElementById('stickerImageInput').value = '';
      return;
    }
    
    this.setState({selectedImages: selectedImages, showImageCountError: false});
  };


  render() {
    const { t } = this.props;

    const ImageError = () => {           
      if (this.state.showImageCountError) {
        return (
          <Typography color='error'>
            {this.state.imageErrorMessage}
          </Typography>
        );
      }
      return <></>;
    };

    return(<>
      <MessageDialog
        open={this.state.showMessageDialog}
        onClose={this.handleCloseImageExceededDialog}
        dialogTitle={this.state.messageTitle}
        dialogContent={this.state.messageContent}
        buttonTitle={this.state.buttonTitle}
      />
      <MobileDetected
        open={this.state.mobileDetected}
        onClose={this.handleCloseMobileDetected}
      />
      {this.state.openCreateStickerDialog && 
        <CreateStickerDialog
          open={this.state.openCreateStickerDialog}
          onClose={this.handleClose}
          stickerAuthor={this.state.stickerAuthor}
          stickerTitle={this.state.stickerTitle}
          stickerImages={this.state.selectedImages}
          debugUseBase64={this.state.debugUseBase64}
        />
      }
      <Page>
        <Grid container padding={5} spacing={2}>
          <PageHeader/>
          <Grid xs={12} item>
            <TextField 
              label={`${t('text_field_title')} (${t('text_optional')})`}
              variant='outlined'
              color='themeColor'
              fullWidth
              onChange={(event) => this.setState({stickerTitle: event.target.value})}
              />
          </Grid>
          <Grid xs={12} item>
            <TextField
              label={`${t('text_field_author')} (${t('text_optional')})`}
              variant='outlined'
              color='themeColor'
              fullWidth
              onChange={(event) => this.setState({stickerAuthor: event.target.value})}
            />
          </Grid>
          <Grid xs={12} item style={{justifyContent: 'left', alignItem: 'center', display: 'flex'}}>
            <Button
              onClick={() => {this.inputRef.current.click();}}
              color='themeColor'
              variant='outlined'
            >
              {t('text_select_images')}
            </Button>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <Typography variant='subtitle2' color='gray' component='div' style={{marginLeft: 10}}>
              {t('text_image_selected')}: {this.state.selectedImages.length} / {MAXIMUM_IMAGE_NUMBER} ({t('text_maximum')})
              </Typography>
            </div>
            <input 
              type='file' name='image_file' className='custom-file-input' id='stickerImageInput' multiple 
              accept='image/png, image/jpeg, image/webp, image/gif'
              style={{display:'none'}}
              onChange={this.saveSelectedImages}
              ref={this.inputRef}
              onClick={(event)=> { event.currentTarget.value = null; }}
            />
          </Grid>
          <Grid xs={12} item>
            <Typography variant='subtitle2' color='gray' component='div'>
              {t('text_supported_format')}: PNG, JPG/JPEG, WEBP, GIF
            </Typography>
            <ImageError />
            <Typography variant='subtitle2' color='gray' component='div'>
              {t('text_terms_and_conditions_2')} <a href={PRIVACY.link}>{t('text_terms_and_conditions')}</a>.
            </Typography>
            
            <Button
              variant='contained' 
              fullWidth
              onClick={this.startCreateSticker}
              id='createSticker-react'
              color='themeColor'
            >
              <Typography variant='subtitle2' color='white' component='div'>
                {t('text_create_sticker')}
              </Typography>
            </Button>
          </Grid>
        </Grid>
      </Page>
      <Statistics/>
    </>);
  }
}

export default withTranslation()(CreateSticker);