
import { createTheme, responsiveFontSizes } from '@mui/material/styles';

let theme = createTheme({      
  palette: {
    primary: {
      main: '#FFFFFF',
      /** @ts-expect-error */
      textColor: '#fff',
    },
    secondary: {
      main: '#5A7CDB',
      /** @ts-expect-error */
      textColor: '#5A7CDB',
    },
    themeColor: {
      main: '#5A7CDB',
      textColor: '#5A7CDB',
      
    },
    background: {
      default: '#394764'
    }
  },
  typography: {
    button: {
      textTransform: 'none'
    }
  },
  MuiButton: {
    raisedPrimary: {
      color: 'white',
    },
  },
});

theme = responsiveFontSizes(theme);

export default theme;