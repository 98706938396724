/* @ts-expect-error */
function getProgressValue(progressValue, totalValue) {
  if (progressValue >= totalValue) {
    return 100;
  } else {
    return (progressValue / totalValue) * 100;
  }
}

export default getProgressValue;
