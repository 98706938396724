import en from './en';
import fr from './fr';
import hk from './hk';
import tw from './tw';

const languages = {
  en,
  fr,
  hk,
  tw,
};

export default languages; 