const content = {
  content_about_us_developer: 'SignalStickerMaker.com（網頁）, Sigicker（iOS）, SignalSticker Maker（Android）皆由以下開發者開發：',
  content_about_us_ads: '此應用程式完全由開發者資助，包括伺服器托管、網絡、應用開發等成本。廣告是我們試圖涵蓋成本的唯一方法。我們已盡量減少廣告的影響。感謝您的理解！',
  faq_question_1: '我上傳的圖片會儲存在你們的資料庫中嗎？',
  faq_answer_1: '不會！貼圖包生成以後，所有圖片都會被刪除！',
  faq_question_2: '我可以修改貼圖包嗎？',
  faq_answer_2: '由於Signal的限制，您只能重新創建整個貼圖。',
  faq_question_3: '可以在貼圖包中指定表情符號和封面圖像嗎？',
  faq_answer_3: '我們正在努力中！請保持關注！',
};

const text = {
  text_about_us: '關於我們',
  text_developers: '開發者',
  text_advertisement: '廣告',
  text_image_selected: '已選圖片',
  text_maximum: '最多',
  text_supported_format: '支援格式',
  text_field_title: '標題',
  text_field_author: '作者',
  text_make_your_own_sticker: '製作你自己的貼圖！',
  text_select_images: '選擇圖片',
  text_create_sticker: '創建貼圖',
  text_terms_and_conditions: '條款和細則',
  text_terms_and_conditions_2: '您在此聲明您已閱讀並同意',
  text_optional: '選填',
  text_download_now: '現在就下載！',
  text_compability: '兼容性',
  text_compability_android: 'Android 4.4+ 的Android設備',
  text_developed_by: '使用以下技術開發：',
  text_ssm_on_android: 'SignalStickerMaker 登錄 Android 啦！',
  text_android_trade_mark: '"Android", "Android Studio", "Google PlayStore" 及其商標皆為 Google LLC 所有。',
  text_ssm_on_ios: 'SignalStickerMaker 登錄 iOS 啦！',
  text_compability_ios_1: '配備 iOS 13+ 的 iPhone, iPad, iPod Touch',
  text_compability_ios_2: '配備 macOS 11.0+ 和 Apple M1晶片的Mac',
  text_ssm_trade_mark: 'Apple®, Mac®, Mac OS®, iPhone®, iPad®, iPod touch®, App Store®, Swift®, Xcode®，及其商標皆為 Apple Inc.在美國和其他國家的註冊商標。',
  text_faq: '常見問題',
  text_stats: '統計',
  text_total_sticker_packs: '在此創建的貼圖包總數',
  text_terms_privacy_condition: '條款、隱私和細則。',
  text_copy_right: '版權所有',
  text_give_app_a_try_question: '試試我們的App版本？',
  text_better_experience: '更好的體驗',
  text_store_your_sticker: '儲存您的貼圖',
  text_more_convenient: '更方便',
  text_no_thanks: '不，謝謝！',
  text_creating_sticker: '正在創建貼圖',
  text_close: '關閉',
  text_cancel: '取消',
  text_copy_to_clipboard: '複製到剪貼簿',
  text_copied: '已複製',
  text_add_sticker_now: '立即添加貼圖',
  text_try_again: '再試一次',
  text_report_to_us: '向我們回報',
  text_reported: '已回報',
  text_image_converting: '正在轉換圖片',
  text_sticker_created_successfully: '成功創建貼圖',
  text_service_busy: '服務忙碌',
  text_upload_failed_try_again: '上傳失敗，請再試一次',
  text_image_uploading: '正在上傳圖片',
  text_step_converting_image: '正在轉換圖片',
  text_step_uploading_image: '正在上傳圖片',
  text_step_result: '結果',  
};

const language_spec = {
  lang_code: 'tw',
  name: '繁體中文',
  flag: '🇹🇼',
};

const menu_items = {
  menu_item_ios: 'iOS',
  menu_item_android: 'Android',
  menu_item_faq: '常見問題',
  menu_item_about_us: '關於我們',
  menu_item_support: '支援',
  menu_item_create_stickers: '創建貼圖',
};

const seo = {
  seo_support_title: '支援',
  seo_support_description: '聯繫我們以獲得支援',
  seo_ios_description: 'SignalStickerMaker.com 的 iOS APP 可在 App Store下載啦！',
  seo_android_description: 'SignalStickerMaker.com 的 Android APP 可在 Google Play Store下載啦！',
  seo_about_us_description: 'SignalStickerMaker.com 的故事',
  seo_faq_title: '常見問題',
  seo_faq_description: '瀏覽常見問題',
};

const error = {
  error_file_size_title: '圖片超過最大檔案大小',
  error_file_size: '壓縮後的圖片檔案大小超過了10 MB。請在上傳前進一步限制您的圖片檔案大小。',
  error_conversion: '圖片轉換失敗。請嘗試使用其他瀏覽器和/或電腦。'
};

const translation = {
  ...language_spec,
  ...content,
  ...text,
  ...menu_items,
  ...seo,
  ...error,
};

export default translation;
