// @ts-nocheck
import * as React from 'react';
import {useEffect} from 'react';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import Button from '@mui/material/Button';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import AppleIcon from '@mui/icons-material/Apple';
import AndroidIcon from '@mui/icons-material/Android';
import iPhone_demo_1 from '../../assets/Image/iPhone_demo_1.webp';
import Android_demo_1 from '../../assets/Image/Android_demo_1.webp';
import {Img} from 'react-image';
import PlaystoreDownload from '../../assets/Image/PlayStore_download.webp';
import AppStore_download from '../../assets/Image/AppStore_download.svg';
import { useTranslation } from 'react-i18next';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role='tabpanel'
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function getMobileOperatingSystem() {
    var userAgent = navigator.userAgent || navigator.vendor || window.opera;

    // Windows Phone must come first because its UA also contains 'Android'
    if (/windows phone/i.test(userAgent)) {
        return 'Windows Phone';
    }

    if (/android/i.test(userAgent)) {
        return 'Android';
    }

    // iOS detection from: http://stackoverflow.com/a/9039885/177710
    if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
        return 'iOS';
    }

    return 'unknown';
}

function MobileDetected(props){
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
  const [value, setValue] = React.useState(0);
  const { t } = useTranslation();

  useEffect(() => {
    let OS = getMobileOperatingSystem();
    if( OS === 'iOS') setValue(0);
    else if(OS === 'Android') setValue(1);
    else if(OS === 'Windwos Phone') console.log('Buy a new phone');
  },[]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return(
    <Dialog
      open={props.open}
      onClose={props.onClose}
      fullScreen={fullScreen}
      maxWidth='md'
      fullWidth
    >
      <DialogTitle
        align='center'
      >
        {t('text_give_app_a_try_question')}
      </DialogTitle>
      <DialogContent>
        <Typography align='center'>
        {t('text_better_experience')} | {t('text_store_your_sticker')} | {t('text_more_convenient')}
        </Typography>

        <Tabs 
          value={value} 
          onChange={handleChange} 
          centered
          indicatorColor='secondary'
          textColor='secondary'
        >
          <Tab icon={<AppleIcon />} label='iOS' />
          <Tab icon={<AndroidIcon />} label='Android' />
        </Tabs>
        
        <TabPanel value={value} index={0}>
          <div style={{justifyContent: 'center', alignItem: 'center', display: 'flex'}}>
            <a href='https://apps.apple.com/bm/app/sigicker-sticker-maker/id1550885981' target='_blank' rel='noreferrer'>
              <Img 
                src={AppStore_download} 
                alt='link to AppStore'
              />
            </a>
          </div>
          <div style={{justifyContent: 'center', alignItem: 'center', display: 'flex'}}>
            <img src={iPhone_demo_1} alt='iPhone App screenshot' width='100%'/>
          </div>
        </TabPanel>
        <TabPanel value={value} index={1}>
          <div style={{justifyContent: 'center', alignItem: 'center', display: 'flex'}}>
            <a href='https://play.google.com/store/apps/details?id=com.app.signalstickermaker' target='_blank' rel='noreferrer'>
              <img src={PlaystoreDownload} alt='Download in Google Play Store' width='150'/>
            </a>
          </div>
          <img src={Android_demo_1} alt='Android App screenshot' width='100%'/>
        </TabPanel>
      </DialogContent>
      <DialogActions>
        <Button
          fullWidth
          onClick={props.onClose}
          color='themeColor'
        >
          {t('text_no_thanks')}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default MobileDetected;