import React from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import {
    IOS,
    Android,
    CreateSticker,
    TermsConditionPrivacy,
    FAQ,
    AboutUs,
    Support
} from '../../../pages';
import Grid from '@mui/material/Grid';

const Switcher = () => (
  <div style={{marginTop: 20, width: '100vw'}}>
    <Grid container justifyContent='center' >
      <Routes>
        <Route 
          path='ios'
          element={<IOS />}
        />
        <Route 
          path='android'
          element={<Android />}
        />
        <Route 
          path='terms_condition_privacy'
          element={<TermsConditionPrivacy />}
        />
        <Route 
          path='privacy'
          element={<TermsConditionPrivacy />}
        />
        <Route 
          path='FAQ'
          element={<FAQ />}
        />
        <Route 
          path='about_us'
          element={<AboutUs />}
        />
        <Route 
          path='support'
          element={<Support />}
        />
        <Route 
          path='create-sticker'
          element={<CreateSticker />}
        />
        <Route 
          path=''
          element={<CreateSticker />}
        />
        <Route 
          path='*'
          element={<Navigate to='create-sticker' />}
        />
      </Routes>
    </Grid>
    </div>
  );

export default Switcher;