// @ts-nocheck
// resize image with original ratio
async function resizeImage(file, size) {
  const imageUrl = URL.createObjectURL(file);
  const img = new Image();
  img.src = imageUrl;
  await new Promise((resolve) => (img.onload = resolve));
  const canvas = document.createElement('canvas');
  const ctx = canvas.getContext('2d');
  canvas.width = img.width > img.height ? size : (size * img.width) / img.height;
  canvas.height = img.width > img.height ? (size * img.height) / img.width : size;
  ctx.drawImage(img, 0, 0, canvas.width, canvas.height);

  // Create new File object with the resized image data and original file information
  const resizedBlob = await new Promise((resolve) =>
    canvas.toBlob(resolve, file.type, 1)
  );
  const resizedFile = new File([resizedBlob], file.name, {
    type: file.type,
    lastModified: file.lastModified,
  });

  return resizedFile;
}

export default resizeImage;
