import _enTranslation from './en.json';
// import _enApiTranslation from './en.api.json';
// import _enSeoTranslation from './en.seo.json';

const translation = {
    ..._enTranslation,
    // ..._enSeoTranslation,
    // ..._enApiTranslation
};

export default translation;