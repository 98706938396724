// @ts-nocheck
import React, {useState} from 'react';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import LinearProgress from '@mui/material/LinearProgress';
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
import ButtonGroup from '@mui/material/ButtonGroup';
import Button from '@mui/material/Button';
import {
  getProgressValue,
} from '../../function';
import { useTranslation } from 'react-i18next';

function LinearProgressWithLabel(props) {
  return (
    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
      <Box sx={{ width: '100%', mr: 1 }}>
        <LinearProgress variant='determinate' {...props} />
      </Box>
      <Box sx={{ minWidth: 35 }}>
        <Typography variant='body2' color='text.secondary'>
          {`${Math.round(props.value,)}%`}
        </Typography>
      </Box>
    </Box>
  );
}

function CircularProgressWithLabel(props) {
  return (
    <Box 
      sx={{ 
        position: 'relative',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        margin: 'auto',
      }}
    >
      <CircularProgress
        variant='determinate' 
        size={100}
        {...props}
      />
      <Box
        sx={{
          position: 'absolute',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Typography 
          variant='h3'
          component='div'
          color='text.secondary'
        >
          {`${Math.round(props.value)}%`}
        </Typography>
      </Box>
    </Box>
  );
}


function ProgressBar(props) {
  const fullScreen = useMediaQuery(useTheme().breakpoints.down('sm'));

  return (
    <>
      {!fullScreen ?
        <LinearProgressWithLabel
          variant='determinate'
          color='themeColor'
          value={props.value}
        />
        :
        <CircularProgressWithLabel
          variant='determinate'
          color='themeColor'
          value={props.value}
        />
      }
    </>
  );
}

function StickerCreationProgress() {
  return (
    <Box 
      sx={{ 
        position: 'relative',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        margin: 'auto',
      }}
    >
      <CircularProgress
        color='themeColor'
        sx={{
          size: 100
        }}
        size={50}
      />
    </Box>
  );
}

function StickerCreationSuccess(props) {
  const [copyToClipboardText, setCopyToClipboardText] = useState('Copy To Clipboard');
  const { t } = useTranslation();
  return (
    <>
      <ButtonGroup
        fullWidth
        color='themeColor'
      >
        <Button
          onClick={() => {
            console.log(props.resultLink);
            window.open(props.resultLink, '_blank'); //to open new page
          }}
        >
          {t('text_add_sticker_now')}
        </Button>
        <Button
          onClick={() => {
            navigator.clipboard.writeText(props.resultLink);
            setCopyToClipboardText(t('text_copied'));
          }}
          onBlur={() => {
            setCopyToClipboardText(t('text_copy_to_clipboard'));
          }}
        >
          {copyToClipboardText}
        </Button>
      </ButtonGroup>
    </>
  );
}

function Error(props) {
  const [disableReportButton, setDisableReportButton] = useState(false);
  const { t } = useTranslation();

  return (
    <ButtonGroup
      variant='outlined'
      fullWidth
      color='themeColor'
    >
      <Button
        onClick={props.startUpload}
      >
        {t('text_try_again')}
      </Button>
      <Button
        disabled={disableReportButton}
        onClick={() =>
          {
            props.reportError();
            setDisableReportButton(true);
          }}
        >
          {!disableReportButton ? <>{t('text_report_to_us')}</> : <>{t('text_reported')}!</>}
        </Button>
      </ButtonGroup>
      );
    }
    
function FileSizeError() {
  const { t } = useTranslation();
  return (
    <Typography>
      {t('error_file_size')}
    </Typography>
  );
}
    
function ConversionError() {
  const { t } = useTranslation();
  return (
    <Typography>
      {t('error_conversion')}
    </Typography>
  );
}
    
function UploadStatusContent(props){
  console.log(props);
    
  switch (props.uploadStatus){
    case 0: // Convert image to webp format
      return(
        <ProgressBar
          value={getProgressValue(props.convertedImageCount, props.convertingImageCount * 3)}
        />
      );
    case 1: // Upload image to S3
      return(
        <ProgressBar
          value={getProgressValue(props.uploadedImageCount, props.uploadingImageCount)}
        />
      );
    case 2: // Creating sticker
      return(
        <StickerCreationProgress />
      );
    case 3: // sticker created
      return(
        <StickerCreationSuccess
          resultLink={props.resultLink}
        />
    );
    case 2.1:
      return(
        <Error
          startUpload={props.startUpload}
          reportError={props.reportError}
         />
    );
    case 1.1:
      return(
        <FileSizeError />
      );
    case 0.1:
    return(
      <ConversionError />
    );
    default:
      return(<>{props.uploadStatus}</>);
    }
}
    
export default UploadStatusContent;