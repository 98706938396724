import axios from 'axios';
/* @ts-expect-error */
async function uploadS3(url, image) {
  console.log('uploadS3');
  console.log(url);
  console.log(image);
  const formData = new FormData();
  formData.append('image', image);
  console.log(formData);
  try{
    const res = await axios.put(url, image, {
      headers: {
        'Content-Type': image.type
      }
    });

    console.log(res);
    return;
  } catch (e) {
    console.log(e);
    throw e;
  }
}

export default uploadS3;