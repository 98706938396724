// @ts-nocheck
import imageCompression from 'browser-image-compression';
import {
  isSafari,
} from '.';
// Convert a single image to webp format

async function convertImageToWebp(image, index) {
  if (!image) return;
  
  console.log('Converting a image to webp ' + index + ': ');
  console.log(image);
  // Load the data into an image
  return await
    new Promise(function (resolve){
      let rawImage = new Image(); 
      rawImage.addEventListener('load', function(){
        console.log(rawImage);  
        resolve(rawImage);
      });
      console.log('test');
      rawImage.src = URL.createObjectURL(image);
    })
    .then((rawImage) => {
      console.log('isSafari() = ', isSafari());
      console.log(rawImage);
      
      if(!isSafari()){
        // // Convert image to webp ObjectURL via a canvas blob
        // console.log('converted to canvas!');
        return new Promise((resolve) => {
          // let canvas = document.getElementById('myCanvas');
          console.log('not safari');
          let canvas = document.createElement('canvas');
          let ctx = canvas.getContext('2d');          
          canvas.width = rawImage.width;
          canvas.height = rawImage.height;
          ctx.drawImage(rawImage, 0, 0);
          // convert canvas to webp image
          canvas.toBlob(function (blob) {
            console.log('create blob');
            let temp = window.URL.createObjectURL(blob);// URL.createObjectURL(blob)
            console.log(temp);
            resolve(temp);
            // URL.createObjectURL
          }, 'image/webp');
        });
      }else{
        return new Promise((resolve) => {
          const imageFile = image;
          console.log('originalFile instanceof Blob', imageFile instanceof Blob); // true
          console.log(`originalFile size ${imageFile.size / 1024 / 1024} MB`);
        
          const options = {
            maxSizeMB: 0.3,
            maxWidthOrHeight: 1080,
            useWebWorker: true,
            fileType: 'png',
            quality:70
          };

          try {
            const compressedFile = imageCompression(imageFile, options);
            console.log(`compressedFile size ${compressedFile.size / 1024 / 1024} MB`); // smaller than maxSizeMB
            resolve(URL.createObjectURL(compressedFile));
          } catch (error) {
            console.log(error);
          }
        });
      }
    })
    .then(function (imageURL) {
      console.log('converted webp');
      // Load image for display on the page
      return new Promise(function (resolve) {
        let scaledImg = new Image();

        scaledImg.addEventListener('load', function () {
          resolve({ imageURL, scaledImg });
        });
        scaledImg.setAttribute('src', imageURL);
        console.log('return!');
        return (imageURL);
      });
    }).catch(error => {
      console.log('error: ', error);
    });
}

export default convertImageToWebp;
