const languageCodeMapping = {
  // English
  'en': 'en',
  'en-au': 'en',
  'en-bz': 'en',
  'en-ca': 'en',
  'en-ie': 'en',
  'en-jm': 'en',
  'en-nz': 'en',
  'en-za': 'en',
  'en-tt': 'en',
  'en-gb': 'en',
  'en-us': 'en',
  // Chinese
  'zh-hk': 'hk',
  'zh-tw': 'tw',
  // French
  'fr': 'fr',
  'fr-ca': 'fr',
  'fr-fr': 'fr',
};

export default languageCodeMapping;