// @ts-nocheck
import Fade from '@mui/material/Fade';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Title from '../Title/index';

function Page(props) {
  return (
    <Fade in={true}>
      <Grid container justifyContent="center" marginTop={2}>
        <Grid item xs={12} sm={10} md={8} lg={6}>
          <Paper>
            <div style={{padding: 20}}>
              <Title
                type="main"
                title={props.pageTitle}
              />
              <div style={{padding: 10}}>
                {props.children}
              </div>
            </div>
          </Paper>
        </Grid>
      </Grid>
    </Fade>
  );
}
export default Page;