import React from 'react';
import ReactGA from 'react-ga';
import { Switcher } from '../components/features/Navigation';
import Header from '../layouts/Header';
import Copyright from '../pages/CopyRight/CopyRight';
import { HelmetProvider } from 'react-helmet-async';
import { SEO } from '../common';
import theme from '../theme';
import { ThemeProvider } from '@mui/material/styles';

class App extends React.Component {
  componentDidMount(){
    document.title = 'Signal Sticker Maker - Make your own stickers';
    // Initalize Google Analytics
    if (document.location.hostname === 'signalstickermaker') { 
      ReactGA.initialize('UA-210359283-1', { debug: true });
      ReactGA.pageview(window.location.pathname);
     } 
  }
  render() {
    return (
      <ThemeProvider theme={theme}>
        <HelmetProvider>
          {/** @ts-expect-error */}
          <SEO/>
          <Header/>
          <Switcher/>
          <Copyright/>
        </HelmetProvider>
      </ThemeProvider>
    );
  }
}
export default App;