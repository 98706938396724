import {
  getAxiosClient,
} from '../common';

/* @ts-expect-error */
async function getPresignedURL(requestIdentifier, imageSpec) {
  const axiosClient = getAxiosClient();
    console.log(axiosClient);
    const payload = {
      requestIdentifier: requestIdentifier,
      images: imageSpec,
    };
    const res = await axiosClient.post('URLs', payload);
    console.log(res);
    return res.data.images;
}

export default getPresignedURL;