import _twTranslation from './tw.json';
// import _enApiTranslation from './zhtw.api.json';
// import _enSeoTranslation from './zhtw.seo.json';

const translation = {
    ..._twTranslation,
    // ..._twSeoTranslation,
    // ..._twnApiTranslation
};

export default translation;