import axios from 'axios';

function getAxiosClient(){
  const apiKey = process.env.REACT_APP_API_KEY || ''; 

  return axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    headers: {
      'Accept': 'application/json',
      'Content-type': 'application/json',
      'x-api-key': apiKey,
    },
  });
}

export { getAxiosClient };