// @ts-nocheck
import Button from '@mui/material/Button';
import { Link } from 'react-router-dom';
import * as ROUTES from '../../../constants/routes';
import { useTranslation } from 'react-i18next';
import LanguageSelection from './LanguageSelection';

function TabButton(props){
  const { i18n, t } = useTranslation();

  return(
    <Button
      component={Link}
      to={`/${i18n.language}/${props.to}`} 
      style={{
        maxHeight: '30px', 
        minWidth: '30px',
        color: 'black',
      }}
    >
      {t(props.name)}
    </Button>
  );
}

function Linker(){
  return(
    <div style={{padding: 5, flexFlow: 'flex', flexDirection: 'row'}}>
      <TabButton
        to={ROUTES.CREATESTICKER.link} 
        name={ROUTES.CREATESTICKER.name}
      />
      <TabButton
        to={ROUTES.IOS.link} 
        name={ROUTES.IOS.name}
      />
      <TabButton
        to={ROUTES.ANDROID.link} 
        name={ROUTES.ANDROID.name}
      />
      <TabButton
        to={ROUTES.FREQUENTLYASKEDQUESTION.link} 
        name={ROUTES.FREQUENTLYASKEDQUESTION.name}
      />
      <TabButton
        to={ROUTES.ABOUTUS.link} 
        name={ROUTES.ABOUTUS.name}
      />
      <TabButton
        to={ROUTES.SUPPORT.link}
        name={ROUTES.SUPPORT.name}
      />
      <LanguageSelection/>
    </div>
  );
}
export default Linker;