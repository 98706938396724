import _frTranslation from './fr.json';
// import _enApiTranslation from './fr.api.json';
// import _enSeoTranslation from './fr.seo.json';

const translation = {
    ..._frTranslation,
    // ..._frSeoTranslation,
    // ..._frnApiTranslation
};

export default translation;