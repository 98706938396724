const content = {
  content_about_us_developer: 'SignalStickerMaker.com (Web), Sigicker(iOS), et SignalSticker Maker(Android) sont développés par: ',
  content_about_us_ads: 'L\'application est entièrement financée par les développeurs, y compris les coûts d\'hébergement de serveurs, de réseau, de développement d\'applications, etc. La publicité est le seul moyen que nous pourrions essayer de couvrir les coûts. Nous avons déjà minimisé l\'impact causé par la publicité. Merci de votre compréhension!',
  faq_question_1: 'Mes photos téléchargées sont-elles stockées dans votre base de données?',
  faq_answer_1: 'Non! Toutes les images sont supprimées après la création du pack de stickers!',
  faq_question_2: 'Puis-je modifier les packs de stickers existants?',
  faq_answer_2: 'En raison de la limitation de Signal, vous pouvez le modifier uniquement en recréant le pack de stickers en entier.',
  faq_question_3: 'Comment puis-je spécifier des emojis et une image de couverture pour mon pack de stickers?',
  faq_answer_3: 'Nous y travaillons! Restez à l\'écoute!',
};

const text = {
  text_about_us: 'À propos de nous',
  text_developers: 'Développeurs',
  text_advertisement: 'Publicité',
  text_image_selected: 'Image sélectionnée',
  text_maximum: 'Maximum',
  text_supported_format: 'Format supporté',
  text_field_title: 'Titre',
  text_field_author: 'Auteur',
  text_make_your_own_sticker: 'Créez vos propres stickers!',
  text_select_images: 'Sélectionner des images',
  text_create_sticker: 'Créer un sticker',
  text_terms_and_conditions: 'termes et conditions',
  text_terms_and_conditions_2: 'Vous déclarez par la présente que vous avez lu et accepté les',
  text_optional: 'Facultatif',
  text_download_now: 'Télécharger maintenant!',
  text_compability: 'Compatibilité',
  text_compability_android: 'Appareil Android avec Android 4.4+',
  text_developed_by: 'Développé en utilisant:',
  text_ssm_on_android: 'SignalStickerMaker sur Android',
  text_android_trade_mark: '"Android", "Android Studio", "Google PlayStore" et leur logo sont des marques commerciales de Google LLC.',
  text_ssm_on_ios: 'SignalStickerMaker sur iOS',
  text_compability_ios_1: 'iPhone, iPad, iPod Touch avec iOS 13+',
  text_compability_ios_2: 'Mac avec macOS 11.0+ et puce Apple M1',
  text_ssm_trade_mark: 'Apple®, Mac®, Mac OS®, iPhone®, iPad®, iPod touch®, App Store®, Swift®, Xcode®, et leurs logos sont des marques déposées d\'Apple Inc. aux États-Unis et dans d\'autres pays.',
  text_faq: 'Foire aux questions',
  text_stats: 'Statistiques',
  text_total_sticker_packs: 'Nombre total de packs de stickers créés en',
  text_terms_privacy_condition: 'Conditions, Confidentialité, et Condition.',
  text_copy_right: 'Droit d\'auteur',
  text_give_app_a_try_question: 'Essayer notre version de l\'application?',
  text_better_experience: 'Meilleure expérience',
  text_store_your_sticker: 'Stockez vos autocollants',
  text_more_convenient: 'Plus pratique',
  text_no_thanks: 'Non, merci!',
  text_creating_sticker: 'Création de stickers',
  text_close: 'Fermer',
  text_cancel: 'Annuler',
  text_copy_to_clipboard: 'Copier dans le presse-papiers',
  text_copied: 'Copié!',
  text_add_sticker_now: 'Ajouter le sticker maintenant',
  text_try_again: 'Réessayer',
  text_report_to_us: 'Signalez-nous cette erreur.',
  text_reported: 'Signalé',
  text_image_converting: 'Conversion des images...',
  text_sticker_created_successfully: 'Sticker créé avec succès!',
  text_service_busy: 'Le service de téléchargement de Signal est occupé, veuillez réessayer dans quelques heures!',
  text_upload_failed_try_again: 'Échec du téléchargement, veuillez réessayer plus tard!',
  text_image_uploading: 'Téléchargement des images...',
  text_step_converting_image: 'Conversion des images',
  text_step_uploading_image: 'Téléchargement des images',
  text_step_result: 'Résultat',
};

const language_spec = {
  lang_code: 'fr',
  name: 'Français',
  flag: '🇫🇷',
};

const menu_items = {
  menu_item_ios: 'iOS',
  menu_item_android: 'Android',
  menu_item_faq: 'FAQ',
  menu_item_about_us: 'À propos de nous',
  menu_item_support: 'Support',
  menu_item_create_stickers: 'Créer des Stickers',
};

const seo = {
  seo_support_title: 'Soutien',
  seo_support_description: 'Contactez-nous pour obtenir de l\'aide',
  seo_ios_description: 'L\'application iOS SignalStickerMaker.com est maintenant disponible dans l\'App Store!',
  seo_android_description: 'L\'application Android SignalStickerMaker.com est maintenant disponible dans Google Play Store!',
  seo_about_us_description: 'L\'histoire de SignalStickerMaker.com',
  seo_faq_title: 'FAQ',
  seo_faq_description: 'Consultez les questions fréquemment posées',
};

const error = {
  error_file_size_title: 'Les images dépassent la taille maximale du fichier',
  error_file_size: 'La taille du fichier image a dépassé 10 Mo après compression. Veuillez limiter davantage la taille de vos fichiers d\'images avant de les télécharger.',
  error_conversion: 'La conversion de l\'image a échoué. Veuillez essayer d\'autres navigateurs et/ou sur des ordinateurs.'
};

const translation = {
  ...language_spec,
  ...content,
  ...text,
  ...menu_items,
  ...seo,
  ...error,
};

export default translation;
