// @ts-nocheck
import { useEffect, useState } from 'react';
import ReactGA from 'react-ga';
import Page from '../../components/library/Page';
import ConstantValues from '../../constants/ConstantValues';
import CircularProgress from '@mui/material/CircularProgress';
import { SEO } from '../../common';
import { formatSEOTitle } from '../../function';
import { useTranslation } from 'react-i18next';

function Support(){
  const [formLoaded, setFormLoaded] = useState(false);
  const { t } = useTranslation();

  useEffect(() => {
    if (document.location.hostname === ConstantValues.SiteHostname) { 
      ReactGA.pageview(window.location.pathname);
    }
  },[]);

  const Content = () => {
    return(
      <div>
        {!formLoaded && (
          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
            <CircularProgress />
            ...Loading
          </div>
        )}
        <iframe
          src="https://docs.google.com/forms/d/e/1FAIpQLSekpN-6wEmN-uu5uUgQBVCYe0mMpeU95OGzfeSja9yECYgr3Q/viewform?embedded=true" 
          width="100%"
          height="1200"
          frameBorder="0"
          marginHeight="0"
          marginWidth="0"
          onLoad={() => setFormLoaded(true)}
          style={{ display: formLoaded ? 'block' : 'none' }}
        />
      </div>
    );
  };
  return(
    <Page>
      <SEO
        title={formatSEOTitle(t('seo_title_support'))}
        description={t('seo_title_support_description')}
      />
      <Content/>
    </Page>
  );  
}

export default Support;
