// @ts-nocheck
import Check from '@mui/icons-material/Check';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Step from '@mui/material/Step';
import StepConnector, { stepConnectorClasses } from '@mui/material/StepConnector';
import StepLabel from '@mui/material/StepLabel';
import Stepper from '@mui/material/Stepper';
import { styled, useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import UploadStatusContent from './UploadStatusContent';
import { useTranslation } from 'react-i18next';

const steps = ['text_step_converting_image', 'text_step_uploading_image', 'text_step_result'];

const QontoConnector = styled(StepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 10,
    left: 'calc(-50% + 16px)',
    right: 'calc(50% + 16px)',
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: '#5A7CDB',
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: '#5A7CDB',
    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    borderColor: theme.palette.mode === 'dark' ? theme.palette.grey[800] : '#eaeaf0',
    borderTopWidth: 3,
    borderRadius: 1,
  },
}));

const QontoStepIconRoot = styled('div')(({ theme, ownerState }) => ({
  color: theme.palette.mode === 'dark' ? theme.palette.grey[700] : '#eaeaf0',
  display: 'flex',
  height: 22,
  alignItems: 'center',
  ...(ownerState.active && {
    color: '#5A7CDB',
  }),
  '& .QontoStepIcon-completedIcon': {
    color: '#5A7CDB',
    zIndex: 1,
    fontSize: 18,
  },
  '& .QontoStepIcon-circle': {
    width: 8,
    height: 8,
    borderRadius: '50%',
    backgroundColor: 'currentColor',
  },
}));

function QontoStepIcon(props) {
  const { active, completed, className } = props;

  return (
    <QontoStepIconRoot ownerState={{ active }} className={className}>
      {completed ? (
        <Check className='QontoStepIcon-completedIcon' />
      ) : (
        <div className='QontoStepIcon-circle' />
      )}
    </QontoStepIconRoot>
  );
}

QontoStepIcon.propTypes = {
  /**
   * Whether this step is active.
   * @default false
   */
  active: PropTypes.bool,
  className: PropTypes.string,
  /**
   * Mark the step as completed. Is passed to child components.
   * @default false
   */
  completed: PropTypes.bool,
};

function UploadingDialog(props) {
  console.log(props);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const [progressStep, setProgressStep] = useState(props.uploadStatus);
  const { t } = useTranslation();
  
  useEffect(() => {
    setProgressStep(props.uploadStatus); 
  }, [props.uploadStatus]);
  
  return (
    <Dialog
      open={props.open}
      fullScreen={fullScreen}
      maxWidth='md'
      fullWidth
    >
      <DialogTitle align='center'>
        {t('text_creating_sticker')}
      </DialogTitle>
      <DialogContent>
        <Grid container style={{ justifyContent: 'center', alignItem: 'center', height: '100%', backgroundColor: ''}} spacing={2}>
          <Grid item xs={12}>
            <Stack sx={{ width: '100%' }} spacing={4}>
              <Stepper alternativeLabel activeStep={progressStep} connector={<QontoConnector />}>
                {steps.map((label) => (
                  <Step key={label}>
                    <StepLabel 
                      StepIconComponent={QontoStepIcon}
                    >
                      {t(label)}
                    </StepLabel>
                  </Step>
                ))}
              </Stepper>
            </Stack>
          </Grid>
          <Grid 
            item 
            xs={12}
            display='flex'
            justifyContent='center'
            alignItems='center'
          >
            <div               
              style={{
                width: '75%',
              }}
            >
              <UploadStatusContent
                uploadStatus={props.uploadStatus}
                stickerImages={props.stickerImages}
                resultLink={props.resultLink}
                startUpload={props.startUpload}
                {...props}
              />
            </div>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button
          fullWidth
          onClick={props.onClose}
          color='themeColor'
        >
          {progressStep === 3 ? t('text_close') : t('text_cancel')}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default UploadingDialog;