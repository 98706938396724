// @ts-nocheck
import Typography from '@mui/material/Typography';
import React, { useEffect } from 'react';
import ReactGA from 'react-ga';
import Page from '../../components/library/Page';
import Title from '../../components/library/Title';
import ConstantValues from '../../constants/ConstantValues';
function TermsConditionPrivacy(){
  useEffect(() => {
    if (document.location.hostname === ConstantValues.SiteHostname) { 
      ReactGA.pageview(window.location.pathname);
    }
  },[]);

  const Content = () => {
    return(
      <>
        <Typography variant="subtitle1" gutterBottom component="div">
          Update: 2021-01-28
        </Typography>
        <Typography variant="subtitle1" gutterBottom component="div">
          “Signal” is the company of “Signal Messenger LLC”
        </Typography>
        <Typography variant="subtitle1" gutterBottom component="div">
          Our valued client, before you use SignalStickerMaker to create stickers, please read this 《Terms and Privacy》 . By using our service, it implies that you have read, understood, and accepted every statement in this document. Otherwise, you should not use this application.
        </Typography>
        <Title type="sub" title="Copy Right"/>
        <Typography variant="subtitle1" gutterBottom component="div">
          By using our service, you have confirmed that you have the ownership or the right to use of the CopyRight of the works that you upload, and be responsible for all the responsibilities that may be caused by the sticker pack you created.
        </Typography>
        <Title type="sub" title="Privacy Policy"/>
        <Typography variant="subtitle1" gutterBottom component="div">
          To provide our service, the images you have uploaded will be held on our server until the sticker pack is created, then those images will be deleted. We will never keep the images that you uploaded. Furthermore, the field you filled in using our service will not be kept as well. To enhance user experience, we might collect your technical details, including IP (Internet Protocol) address, browser type, time zone setting, browser plugins and version, operating system, platform, and other technology that is used in browsing signalstickermaker.com. These details will not be shared to the third party.
        </Typography>
        <Title type="sub" title="Disclamier"/>
        <Typography variant="subtitle1" gutterBottom component="div">
          SignalStickerMaker. only upload the images to Signal, we do not have the right to manage the created stickers. If you have any problem with the created stickers, you should contact Signal. You should not use our application in any illegal activity. This 《Terms and Privacy》may update at any moment without prior notice, please visit this 《Terms and Privacy》 from time to time in order to get the updated version. In case of any discrepancies or inconsistencies between the English and Chinese versions of this 《Terms and Privacy》, the English version shall prevail.    
        </Typography>
      </>
    );
  };
  return(
    <Page pageTitle="Terms, Condition, and Policy">
      <Content/>
    </Page>
  );
}

export default TermsConditionPrivacy;