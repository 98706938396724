import React from 'react';
import ReactDOM from 'react-dom';
import './function/i18n';
import RoutesByLanguage from './routes/RoutesByLanguage';
import { BrowserRouter } from 'react-router-dom';
console.log('Why are you looking at the console??');
/** @ts-expect-error */
console.disableYellowBox = true;

// hide console.log on production
// if (process.env.NODE_ENV !== 'development'){
//   console.log = () => { };
// }

const App = () => {
  return (
    <React.StrictMode>
      <BrowserRouter>
        <RoutesByLanguage />
      </BrowserRouter>
    </React.StrictMode>
  );
};

ReactDOM.render(<App />, document.getElementById('root'));

const rootElement = document.getElementById('root');
/** @ts-expect-error */
if (rootElement.hasChildNodes()) {
  ReactDOM.hydrate(<App />, rootElement);
} else {
  ReactDOM.render(<App />, rootElement);
}


// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.unregister();
