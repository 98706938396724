const SITE_HOST_NAME = 'https://signalstickers.com';
const MAXIMUM_IMAGE_NUMBER = 150;
const SEO_TITLE = 'SignalStickerMaker - Create Signal stickers pack in one click';
const SEO_TYPE = 'website';
const SEO_DESCRIPTION = 'Upload your image and turn it into a Signal sticker pack in one click! Safe, fast, and free.';
const SEO_NAME = 'SignalStickerMaker';
export { 
  SITE_HOST_NAME,
  MAXIMUM_IMAGE_NUMBER,
  SEO_TITLE,
  SEO_TYPE,
  SEO_DESCRIPTION,
  SEO_NAME,
};