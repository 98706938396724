// @ts-nocheck
import App_Icon from '../../assets/Image/App_Icon.webp';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { useTranslation } from 'react-i18next';

function PageHeader(){
  const { t } = useTranslation();
  return(
    <Grid container style={{justifyContent: 'center', alignItem: 'center', display: 'flex'}}>
      <Grid xs={12} item style={{justifyContent: 'center', alignItem: 'center', display: 'flex'}}>
        <Box
          component='img'
          sx={{
            width: 350,
            maxWidth: { xs: 200, md: 250 },
          }}
          alt='Signal Sticker Maker Icon'
          src={App_Icon}
        />
      </Grid>
      <Grid xs={12} item style={{justifyContent: 'center', alignItem: 'center', display: 'flex'}}>
        <Typography variant='h4' color='themeColor'>
        {t('text_make_your_own_sticker')}
        </Typography>
        {/* <CircularProgress color='primary' /> */}
      </Grid>
    </Grid>
  );
}

export default PageHeader;