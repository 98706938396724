// @ts-nocheck
import React from 'react';
import { v4 as uuidv4 } from 'uuid';
import {
  blobToFile,
  convertImageToWebp,
  resizeImage,
  hashString,
} from '../../function';
import UploadingDialog from './UploadingDialog';
import { getPresignedURL, createSticker as createStickerService, uploadS3 } from '../../service';
import { withTranslation  } from 'react-i18next';

const maxFileSize = 10 * 1024 * 1024; // 10MB

class CreateStickerDialog extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      uploadStatus: 0, // the status of uploading images
      // 0:   Converting to webp
      // 0.1: ERROR: file convertion error
      // 1:   Uploading image to S3
      // 1.1: ERROR: upload failed
      // 1.2: ERROR: file size exceed limit
      // 2:   Creating Sticker
      // 2.1: ERROR: create failed
      // 3:   Sticker created
      dialogTitle: '',
      resultLink: '',
      copyToClipboardText: '',
      debugImages: [],
      filesSize: 0,
      receivedErrorMessage: '',
      currentUUID: '',
      // Convert image to webp
      convertingImageCount: 0, // total number of image to be converted
      convertedImageCount: 0, // total number of image converted
      // Upload image to S3
      uploadingImageCount: 0, // total number of image to be uploaded
      uploadedImageCount: 0, // total number of image uploaded
    };
  }

  componentDidMount() {
    this.startCreateSticker();
    this.reportError();
  }

  startCreateSticker = async () => {
    console.log('startCreateSticker');
    console.log(this.props.stickerImages);
    let images = [];
    // Convert Image to Webp
    this.setState({ uploadStatus: 0 });
    images = await this.convertImagesToWebp();

    // Upload Images to S3
    const requestUUID = uuidv4();
    this.setState({ uploadStatus: 1 });
    await this.uploadImages(images, requestUUID);

    // Request Sticker Creation
    this.setState({ uploadStatus: 2 });
    await this.requestCreateSticker(requestUUID);
  };

  // Convert all selected images to webp format if available (i.e. not using Safari)
  // https://codepen.io/nosilleg/pen/XMdvoM?editors=0011
  convertImagesToWebp = async () => {
    console.log('==== convert images to webp ====');
    const { t } = this.props;
    this.setDialogTitle(t('text_image_converting'));
    try {
      // set converting image count
      this.setState({convertingImageCount: this.props.stickerImages.length});

      // add count function
      const addCount = (count) => {
        this.setState({ convertedImageCount: this.state.convertedImageCount + count });
      };

      const images = Array.from(this.props.stickerImages);
      console.log(images);
      const tempWebpImages = await Promise.all(
        images.map(async (image, index) => {
          console.log(image);
          const imageType = image.type;
          console.log('image type: ' + imageType);
          let convertedImage = null;
          // If image is not webp, convert it to webp
          if (imageType === 'image/webp' || imageType === 'image/gif') {
            console.log(`Image is ${imageType}, no need to convert`);
            convertedImage = image;
            addCount(3);
          } else {
            console.log('Is not webp, convert now');
            convertedImage = await convertImageToWebp(image, index);
            addCount(1);
            convertedImage = await blobToFile(convertedImage, `${uuidv4()}.webp`);
            addCount(1);
            convertedImage = await resizeImage(convertedImage, 512);
            addCount(1);
          }
          return convertedImage;
        })
      );
      return tempWebpImages;
    } catch (error) {
      console.log('process error: ', error);
    }
  };
  
  setDialogTitle = (title) => {
    this.setState({
      dialogTitle: title
    });
  };

  handleCreateStickerResult = (res) => {
    console.log('handleCreateStickerResult');
    console.log(res);
    const { t } = this.props;
    if (res.result) {
      this.setState({
        uploadStatus: 3,
        dialogTitle: t('text_sticker_created_successfully'),
        resultLink: res.result,
      });
    } else {
      throw res.message;
    }
  };

  requestCreateSticker = async (requestUUID) => {
    console.log('requestCreateSticker');
    const { t } = this.props;
    let data = {
      title: this.props.stickerTitle || 'Untitled',
      author: this.props.stickerAuthor || 'Anonymous',
      path: requestUUID
    };

    // set skip_upload to true if in development mode
    if (process.env.NODE_ENV === 'development') {
      data.skipUpload = true;
    }

    try {
      await createStickerService(data, this.handleCreateStickerResult);
    } catch (error) {
      console.log(error);
      var dialogTitle = '';
      if (error.toString().includes('Error: rate exceeded')) {
        dialogTitle = t('text_service_busy');
      } else {
        dialogTitle = t('text_upload_failed_try_again');
      }
      this.setState({
        receivedErrorMessage: error,
        uploadStatus: 2.,
        currentUUID: requestUUID,
        dialogTitle: dialogTitle
      });
    }
  };

  uploadImages = async (images, requestUUID) => {
    const { t } = this.props;
    this.setState({dialogTitle: t('text_image_uploading')});
    
    console.log('==== Uploading ====');

    if (this.state.filesSize > maxFileSize) {
      console.log('exceeded limit');
      this.setState({ uploadStatus: 1.2, dialogTitle: t('error_file_size_title') });
      return;
    }

    // add count function
    const addCount = (count) => {
      this.setState({ uploadedImageCount: this.state.uploadedImageCount + count });
    };

    try{
      // Get Presigned URL 
      let imageSpec = {};
      images.map((image) => {
        imageSpec[`${hashString(image.name)}`] = {
          extension: image.name.split('.').pop(),
        };
      });
      console.log(imageSpec);
      imageSpec = await getPresignedURL(requestUUID, imageSpec);
      console.log(imageSpec);

      // Upload image to S3
      this.setState({ uploadingImageCount: images.length});
      await Promise.allSettled(
        images.map(async (image, index) => {
          console.log(image);
          console.log(imageSpec[`${hashString(image.name)}`]);
          const presignedURL = imageSpec[`${hashString(image.name)}`].presignedURL;
          console.log(presignedURL);
          await uploadS3(presignedURL, images[index]);
          addCount(1);
        })
      );
      console.log('requestUUID = ', requestUUID);
      return;
    }
    catch (error){
      console.log('upload error: ', error);
      this.setState({
        uploadStatus: 2.1,
      });
      throw error;
    }
  };

  reportError = async () => {
    // console.log(this.state.currentUUID)
    let data = {
      uuid: this.state.currentUUID,
      error: this.state.receivedErrorMessage
    };
    try {
      if (data.uuid!=='') {
        await fetch(process.env.REACT_APP_API_URL + 'recordError',
          {
            method: 'POST',
            body: JSON.stringify(data)
          });
        console.log('Error reported.');
      }
      else{
        console.log('not logged');
      }
    }
    catch (error) {
      console.log(error);
    }
  };

  render() {

    return (
      <UploadingDialog
        open={this.props.open}
        onClose={this.props.onClose}
        // createSticker={this.createSticker}
        reportError={this.reportError}
        debugImages={this.state.debugImages}
        resultLink={this.state.resultLink}
        // dialogTitle={this.state.dialogTitle}
        stickerTitle={this.props.stickerTitle}
        stickerAuthor={this.props.stickerAuthor}
        stickerImages={this.props.stickerImages}
        uploadStatus={this.state.uploadStatus}

        copyToClipboardText={this.state.copyToClipboardText}
        // Convert image to webp
        convertedImageCount={this.state.convertedImageCount}
        convertingImageCount={this.state.convertingImageCount}
        // Uploading image to S3
        uploadingImageCount={this.state.uploadingImageCount}
        uploadedImageCount={this.state.uploadedImageCount}
      />
    );
  }
}

export default withTranslation()(CreateStickerDialog);