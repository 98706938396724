import React from 'react';
import { Link } from 'react-router-dom';
import App_Icon from '../../../assets/Image/App_Icon_transparent.webp';
import { HOME } from '../../../constants/routes';

function Logo(){
  return(
    <div style={{justifyContent: 'flex-start', alignItem: 'center', display: 'flex'}}>
      <Link to={HOME.link} className="font-bold text-white text-2xl ">
        <span className="flex w-10 h-10 rounded-full">
          <img src={App_Icon} alt="icon" width={50}/>
        </span>
      </Link>
    </div>
  );
}

export default Logo;