// @ts-nocheck
import WebSocket from 'isomorphic-ws';

async function createSticker(data, callback) {
  console.log(data);
  return new Promise((resolve, reject) => {
    const ws = new WebSocket(process.env.REACT_APP_WEBSOCKET_URL);
    console.log(ws);
    const input = Object.assign({ action: 'createSticker'}, data);
    ws.onopen = (event) => {
      console.log(event);
      ws.send(JSON.stringify(input));
    };
    
    ws.addEventListener('close', () => {
      console.log('close');
    });

    ws.addEventListener('message', (event) => {
      console.log('message');
      console.log(event);
      // Parse the response as JSON
      const res = JSON.parse(event.data);
      console.log(res);
      if (res.error) {
        // If there is an error, reject the promise with the error message
        reject(res.error);
      } else {
        // If there are no errors, resolve the promise with the data from the response
        callback(res);
        resolve(res.data);
      }
      ws.close();
    });

    ws.addEventListener('error', (error) => {
      // If there is an error with the WebSocket connection, reject the promise with the error
      console.log('error');
      console.log(error);
      reject(error);
    });
  });
}

export default createSticker;
