// @ts-nocheck
import {useState, useEffect} from 'react';
import Page from '../../components/library/Page';
import Typography from '@mui/material/Typography';
import CircularProgress from '@mui/material/CircularProgress';
import LinearProgress from '@mui/material/LinearProgress';
import { 
  getAxiosClient
} from '../../common';
import { useTranslation } from 'react-i18next';

function Statistics(){
  const [loading, setLoading] = useState(true);
  const [stickerCount, setStickerCount] = useState(0);
  const { t } = useTranslation();

  useEffect(() => {
    getStickerCount();
  },[]);

  const getStickerCount = async () => {
    console.log('getStickerCount');
    const axiosClient = getAxiosClient();
    try {
      const res = await axiosClient.get('stickerCount');
      if ( !Object.hasOwnProperty.call(res.data, 'stickerCount')) {
        throw new Error('stickerCount not found in response');
      }
      setStickerCount(res.data.stickerCount);
      setLoading(false);
    } catch (e) {
      console.error(e);
    }
  };

  const StickerCount = () =>{
    return loading ? <CircularProgress variant='determinate'/> : stickerCount;
  };

  return(
    <Page pageTitle={t('text_stats')}>
      <Typography variant='subtitle1' >
        {t('text_total_sticker_packs')} SignalStickerMaker.com: <StickerCount/>
      </Typography>
      <LinearProgress/>
    </Page>
  );
}

export default Statistics;