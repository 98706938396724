const content = {
  content_about_us_developer: 'SignalStickerMaker.com (Web), Sigicker(iOS), and SignalSticker Maker(Android) are developed by: ',
  content_about_us_ads: 'The application is solely funded by the developers, including the cost in server hosting, networking, app development, and so on. Advertisement is the only way we could try to cover the cost. We have already minimize the affect made by the advertisment. Thank you for your understanding!',
  faq_question_1: 'Are my uploaded photos stored in your database?',
  faq_answer_1: 'No! All the images are deleted after the sticker pack is generated!',
  faq_question_2: 'Can I modify the existing sticker packs?',
  faq_answer_2: 'Due to the limitation of Signal, you can modify it only by recreating the whole sticker pack.',
  faq_question_3: 'How can I specify emojis and cover image for my sticker pack?',
  faq_answer_3: 'We are working on it! Please stay tuned!',
};

const text = {
  text_about_us: 'About Us',
  text_developers: 'Developers',
  text_advertisement: 'Advertisement',
  text_image_selected: 'Image selected',
  text_maximum: 'Maximum',
  text_supported_format: 'Supported format',
  text_field_title: 'Title',
  text_field_author: 'Author',
  text_make_your_own_sticker: 'Make Your Own Stickers!',
  text_select_images: 'Select Images',
  text_create_sticker: 'Create Sticker',
  text_terms_and_conditions: 'terms and conditions',
  text_terms_and_conditions_2: 'You are here by declaring that you have read and agree to the',
  text_optional: 'Optional',
  text_download_now: 'Download now!',
  text_compability: 'Compability',
  text_compability_android: 'Android device with Android 4.4+',
  text_developed_by: 'Developed by using:',
  text_ssm_on_android: 'SignalStickerMaker on Android',
  text_android_trade_mark: '"Android", "Android Studio", "Google PlayStore" and their logo are the trademark of Google LLC.',
  text_ssm_on_ios: 'SignalStickerMaker on iOS',
  text_compability_ios_1: 'iPhone, iPad, iPod Touch with iOS 13+',
  text_compability_ios_2: 'Mac with macOS 11.0+ and Apple M1 chip',
  text_ssm_trade_mark: 'Apple®, Mac®, Mac OS®, iPhone®, iPad®, iPod touch®, App Store®, Swift®, Xcode®, and their logos are registered trademarks of Apple Inc. in the United States and other countries.',
  text_faq: 'Frequently Asked Questions',
  text_stats: 'Statistics',
  text_total_sticker_packs: 'Total number of sticker packs created in',
  text_terms_privacy_condition: 'Terms, Privacy, and Condition.',
  text_copy_right: 'Copyright',
  text_give_app_a_try_question: 'Give a try to our app version?',
  text_better_experience: 'Better experience',
  text_store_your_sticker: 'Store your stickers',
  text_more_convenient: 'More convenient',
  text_no_thanks: 'No, thanks!',
  text_creating_sticker: 'Creating Stickers',
  text_close: 'Close',
  text_cancel: 'Cancel',
  text_copy_to_clipboard: 'Copy to Clipboard',
  text_copied: 'Copied!',
  text_add_sticker_now: 'Add Sticker Now',
  text_try_again: 'Try Again',
  text_report_to_us: 'Report this error to us.',
  text_reported: 'Reported',
  text_image_converting: 'Converting the images...',
  text_sticker_created_successfully: 'Sticker Created Successfully!',
  text_service_busy: 'Signal upload service is busy, please try again in a few hours!',
  text_upload_failed_try_again: 'Uploaded Failed, please try again later!',
  text_image_uploading: 'Uploading the images...',
  text_step_converting_image: 'Converting images',
  text_step_uploading_image: 'Uploading images',
  text_step_result: 'Result',
};

const language_spec = {
  lang_code: 'en',
  name: 'English',
  flag: '🇺🇸',
};

const menu_items = {
  menu_item_ios: 'iOS',
  menu_item_android: 'Android',
  menu_item_faq: 'FAQ',
  menu_item_about_us: 'About Us',
  menu_item_support: 'Support',
  menu_item_create_stickers: 'Create Stickers',
};

const seo = {
  seo_support_title: 'Support',
  seo_support_description: 'Contact us for support',
  seo_ios_description: 'The SignalStickerMaker.com iOS App is now available in App Store!',
  seo_android_description: 'The SignalStickerMaker.com Android App is now available in Google Play Store!',
  seo_about_us_description: 'The story of SignalStickerMaker.com',
  seo_faq_title: 'FAQ',
  seo_faq_description: 'Browse the frequently asked questions',
};

const error = {
  error_file_size_title: 'Images exceed maximum file size',
  error_file_size: 'The image file size exceeded 10 MB after compression. Please further limit your images file size before uploading.',
  error_conversion: 'The image conversion failed. Please try other browsers and/or on computers.'
};

const translation = {
  ...language_spec,
  ...content,
  ...text,
  ...menu_items,
  ...seo,
  ...error,
};

export default translation;