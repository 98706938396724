// @ts-nocheck
import React from 'react';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';

function MessageDialog(props){
  return(
    <Dialog
      open={props.open}
      onClose={props.onClose}
      maxWidth='md'
      fullWidth
    >
      <DialogTitle
        align='center'
      >
        {props.dialogTitle}
      </DialogTitle>
      <DialogContent>
        <Typography align='center'>
          {props.dialogContent}
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button
          fullWidth
          onClick={props.onClose}
        >
          {props.buttonTitle}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default MessageDialog;