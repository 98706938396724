// @ts-nocheck
import {useEffect} from 'react';
import iPhone_demo_1 from '../../assets/Image/iPhone_demo_1.webp';
import Grid from '@mui/material/Grid';
import AppStore_download from '../../assets/Image/AppStore_download.svg';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import {Img} from 'react-image';
import React_Native_Icon from '../../assets/Image/React_Native_icon.webp';
import ReactGA from 'react-ga';
import ConstantValues from '../../constants/ConstantValues';
import Fade from '@mui/material/Fade';
import Page from '../../components/library/Page';
import { SEO } from '../../common';
import { formatSEOTitle } from '../../function';
import { useTranslation } from 'react-i18next';
function IOS(){
  const { t } = useTranslation();
  useEffect(() => {
    if (document.location.hostname === ConstantValues.SiteHostname) { 
      ReactGA.pageview(window.location.pathname);
    }
  },[]);
  return(
    <Page>
      <SEO
        title={formatSEOTitle('iOS')}
        description={t('seo_ios_description')}
      />
      <Fade in={true}>
        <Grid container padding={5} style={{alignItems: 'center', justifyContent: 'center'}}>
          <Grid item sm={6} xs={12}>
            <img src={iPhone_demo_1} alt='iPhone App screenshot' width='100%'/>
          </Grid>
          <Grid item sm={6} xs={12}
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              height: '100%',
              // backgroundColor: 'grey'
            }}
          >
            <Grid container spacing={5} style={{justifyContent: 'center', padding: 15}}>
              <Grid item xs={12}>
                <Typography align='center' variant='h5'>
                  {t('text_ssm_on_ios')}
                </Typography>
                <Typography align='center' variant='h6'>
                  {t('text_download_now')}
                </Typography>
                <div style={{justifyContent: 'center', alignItem: 'center', display: 'flex'}}>
                  <a href='https://apps.apple.com/bm/app/sigicker-sticker-maker/id1550885981' target='_blank' rel='noreferrer'>
                    <Img src={AppStore_download} alt='link to AppStore'/>
                  </a>
                </div>
              </Grid>
              <br></br><Divider/><br></br>
              <Grid item xs={12}>
                <Typography>
                  {t('text_compability')}
                </Typography>
                <Typography variant='subtitle1'>
                  <ul>
                    <li>{t('text_compability_ios_1')}</li>
                    <li>{t('text_compability_ios_2')}</li>
                  </ul>
                </Typography>
              </Grid>
              <br></br><Divider/><br></br>
              <Grid item xs={12}>
                <Typography>
                  {t('text_developed_by')}
                </Typography>
                <div style={{float: 'left', display: 'flex'}}>
                  <img src={React_Native_Icon} alt='React Native Icon' style={{padding: 5, height: 75}}/>
                </div>
              </Grid>
              <Divider/>
              <Grid item xs={12}>
                <Typography variant='subtitle2' color='gray' component='div'>
                  {t('text_ssm_trade_mark')}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Fade>
    </Page>
  );
}

export default IOS;