// @ts-nocheck
import Typography from '@mui/material/Typography';

export const StyledTitle = ({type, title}) => {
  var component = '';
  var styles = {};
  var sx = {};
  if(type === 'main'){
    component = '';
    styles={
      maringTop: '1rem',
      marginBottom: '1rem'
    };
    sx = {
      fontWeight: 'medium',
      fontSize: 30
    };
  }

  if(type === 'sub'){
    component = '';
    styles={
      maringTop: '2rem',
      marginBottom: '0.3rem'
    };
    sx = {
      fontWeight: 'medium',
      fontSize: 23
    };
  }


  return(
    <div 
      style={styles}
    >
      <Typography
        component={component}
        sx={sx}
      >
        {title}
      </Typography>
    </div>
  );
};