// @ts-nocheck
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';
import Button from '@mui/material/Button';
import {
  PRIVACY
} from '../../constants/routes';
import { useTranslation } from 'react-i18next';

export default function Copyright() {
  const { t } = useTranslation();

  console.log(PRIVACY.link);

  return (
    <div style={{marginTop: 15, marginBottom: 50}}>
      <Typography variant="body2" color="textSecondary" align="center">
        {t('text_copy_right')} {' © '}
        <Link color="inherit" href="https://signalstickermaker.com/">
          SignalStickerMaker.com 
        </Link>
        {' '}
        2021 - {new Date().getFullYear()}
        {'.'}
      </Typography>
      <Typography variant="body2" color="textSecondary" align="center">
        <Button
          color="inherit" 
          href={PRIVACY.link} 
          component={Link}
        >
          {t('text_terms_privacy_condition')}
        </Button>
      </Typography>
    </div>
  );
}