// @ts-nocheck
import Fade from '@mui/material/Fade';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { useEffect } from 'react';
import ReactGA from 'react-ga';
import Android_demo_1 from '../../assets/Image/Android_demo_1.webp';
import PlaystoreDownload from '../../assets/Image/PlayStore_download.webp';
import React_Native_Icon from '../../assets/Image/React_Native_icon.webp';
import Page from '../../components/library/Page';
import ConstantValues from '../../constants/ConstantValues';
import { SEO } from '../../common';
import { formatSEOTitle } from '../../function';
import { useTranslation } from 'react-i18next';

function Android(){
  const { t } = useTranslation();

  useEffect(() => {
    if (document.location.hostname === ConstantValues.SiteHostname) { 
      ReactGA.pageview(window.location.pathname);
    }
  },[]);

  return(
    <Page>
      <SEO
        title={formatSEOTitle('Android')}
        description={t('seo_android_description')}
      />
      <Fade in={true}>
        <Grid container padding={5} style={{alignItems: 'center', justifyContent: 'center'}}>
          <Grid item sm={6} xs={12}>
            <img src={Android_demo_1} alt='Android App screenshot' width='100%'/>
          </Grid>
          <Grid item sm={6} xs={12}
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              height: '100%',
            }}
          >
            <Grid container spacing={5} style={{justifyContent: 'center', padding: 15}}>
              <Grid item xs={12}>
                <Typography align='center' variant='h5'>
                  {t('text_ssm_on_android')}!
                </Typography>
                <Typography align='center' variant='h6'>
                  {t('text_download_now')}
                </Typography>
                <div style={{justifyContent: 'center', alignItem: 'center', display: 'flex'}}>
                  <a href='https://play.google.com/store/apps/details?id=com.app.signalstickermaker' target='_blank' rel='noreferrer'>
                    <img src={PlaystoreDownload} alt='Download in Google Play Store' height='50' />
                  </a>
                </div>
              </Grid>
              <Grid item xs={12}>
                <Typography>
                {t('text_compability')}: 
                </Typography>
                <Typography variant='subtitle1'>
                  <ul>
                    <li>{t('text_compability_android')}</li>
                  </ul>
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography>
                {t('text_developed_by')}: 
                </Typography>
                <div style={{float: 'left', display: 'flex'}}>
                  <img src={React_Native_Icon} alt='React Native Icon' style={{padding: 5, height: 75}}/>
                </div>
              </Grid>
              <Grid item xs={12}>
                <Typography variant='subtitle2' color='gray' component='div'>
                  {t('text_android_trade_mark')}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Fade>
    </Page>
  );  
}

export default Android;